import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';
import iconDown from '../images/icons/down.svg';

const IndexPage = (props) => {
	const postList = props.data.allMarkdownRemark;
	//console.log(postList.edges)
	return (
		<div>

			<div className="indexCover bg-cover flex h-screen items-center justify-center text-white w-full">
				<div className="c-triangle z-10"></div>
				<div className="indexCoverText bg-black-60 text-center p-3 w-1/2 z-20">
					<h1 className="text-5xl">Staple</h1>
					<div className="text-2xl opacity-75">/ˈsteɪp(ə)l/</div>
					<div className="mt-3 text-1xl md:text-2xl font-thin">A main or important element of something</div>
				</div>
				<a href="#blog" className="absolute pin-b z-20 mb-4">
					<img src={iconDown} className="w-10" alt="Down" />
				</a>
			</div>

			<Layout>
				<SEO
					title="Staple Home"
					keywords={['Staple', 'Gwilym', 'New Zealand', 'Developer']}
				/>

				<div id="blog" className="anchor"></div>

				<div className="flex flex-wrap pt-6 pb-6 items-centerx justify-center w-full">
						{postList.edges.map(({ node }, i) => (
							<Link to={node.fields.slug} className="link w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 mb-5 bg-white no-underline" key={i}>
								<div className="m-5">
									<Img fluid={node.frontmatter.cover_image.childImageSharp.fluid} className="w-full" alt={node.frontmatter.title} />
									<h3 className="leading-tight text-black mt-2">{node.frontmatter.title}</h3>
									<div className="mt-0 mb-2 leading-normal text-sm text-black">{node.frontmatter.date}</div>
									<div className="mt-2 leading-normal text-black font-serif">{node.excerpt}</div>
								</div>
							</Link>
						))}
				</div>

			</Layout>

		</div>
	);
};

export default IndexPage;

export const listQuery = graphql`
	query ListQuery {
		allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
			edges {
				node {
					fields{
						slug
					}
					excerpt(pruneLength: 250)
					frontmatter {
						date(formatString: "MMMM Do YYYY")
						title
						cover_image {
							publicURL
							childImageSharp {
								fluid(maxWidth: 600) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}
	}
`
